// Load Bootstrap JS
import bootstrap from 'bootstrap';
import $ from "jquery";
window.jQuery = $;
import 'slick-carousel/slick/slick';

// Load Styles
import '../scss/main.scss';
// App code
// console.log(`Hello ${process.env.HELLO}`);

window.addEventListener("load", function() {
  window.dispatchEvent(new Event('resize'));
});

const offcanvasMenu = document.getElementById('offcanvasMenu');

// Event-Listener für das Schließen des Offcanvas-Menüs hinzufügen
offcanvasMenu.addEventListener('shown.bs.offcanvas', function () {
  offcanvasMenu.focus();
});

offcanvasMenu.addEventListener('hidden.bs.offcanvas', function () {
  document.querySelector('[data-bs-target="#offcanvasMenu"]').focus();
});

// Event-Listener für den Button "Suchen" hinzufügen
document.getElementById('opensearch').addEventListener('click', function (event) {
  event.preventDefault(); // Verhindert das Standardverhalten des Links
  var formElement = document.getElementById('search_it-form');
  formElement.className = 'open';

  // Warte einen kurzen Moment, bevor das Eingabefeld fokussiert wird
  setTimeout(function () {
    document.querySelector('.searchtext').focus();
  }, 1000);
});

document.getElementById('closer').addEventListener('click', function (event) {
  event.preventDefault(); // Verhindert das Standardverhalten des Links
  var formElement = document.getElementById('search_it-form');
  formElement.classList.remove('open');
});

document.addEventListener("keydown", function(event) {
  // Überprüfen, ob die Escape-Taste gedrückt wurde
  if (event.key === "Escape") {
    // Überprüfen, ob der Fokus innerhalb der gewünschten <div> liegt
    const div = document.getElementById("search_it-form");
    if (div.contains(document.activeElement)) {
      div.classList.remove("open");
      document.body.focus();
    }
  }
});

/*
var myOffcanvas = document.getElementById('offc');
var main = document.getElementById('main');

function resize() {
  alert('jipp');
  var w = myOffcanvas.clientWidth;
  var ww = window.innerWidth;
  if( ww > 767) {
    main.style.paddingRight = w + 30 + "px";
  }
  else {
    main.style.paddingRight = "15px";
  }
}

window.onresize = resize;
 */

$(window).on("load", function (e) {
  $('body').addClass('loaded');
});

$(document).ready(function(){
  $('.startslider').slick({
    autoplay: true,
    autoplaySpeed: 7000,
    fade: false,
    arrows: true,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
      // You can unslick at a given breakpoint now by adding:
      // settings: "unslick"
      // instead of a settings object
    ],
    prevArrow: '<a class="slick-prev"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36.26 42.27"><defs><style>.cls-1{fill:#28285d;}</style></defs><path id="Pfad_29" data-name="Pfad 29" style="fill: #28285d" d="M0,18.65V16.32H3.5A12.84,12.84,0,0,0,16.32,3.51V0h14.6V3.51A27.45,27.45,0,0,1,3.51,30.92H0Z"/><circle id="Ellipse_12" data-name="Ellipse 12" style="fill: #28285d" cx="22.55" cy="37.69" r="4.58"/></svg></a>',
    nextArrow: '<a class="slick-next"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 35.97 51.21"><defs><style>.cls-1{fill:#28285d;}</style></defs><g id="Gruppe_31" data-name="Gruppe 31"><rect id="Rechteck_22" data-name="Rechteck 22" style="fill: #28285d" x="10.68" y="-0.15" width="14.61" height="36.26" transform="translate(-7.45 17.98) rotate(-45)"/><rect id="Rechteck_23" data-name="Rechteck 23" style="fill: #28285d" x="2.86" y="33.11" width="15.95" height="14.61" transform="translate(-25.4 19.5) rotate(-45)"/></g></svg></a>',
  });


  $('.imageslider').slick({
    autoplay: true,
    autoplaySpeed: 12000,
    fade: false,
    arrows: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: ' <button type="button" data-role="none" aria-label="Vorher" class="slick-prev" href=""><svg aria-label="Vorher" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36.26 42.27"><defs><style>.cls-1{fill:#28285d;}</style></defs><path id="Pfad_29" data-name="Pfad 29" style="fill: #28285d" d="M0,18.65V16.32H3.5A12.84,12.84,0,0,0,16.32,3.51V0h14.6V3.51A27.45,27.45,0,0,1,3.51,30.92H0Z"/><circle id="Ellipse_12" data-name="Ellipse 12" style="fill: #28285d" cx="22.55" cy="37.69" r="4.58"/></svg></button>',
    nextArrow: '<button type="button" data-role="none" aria-label="Nächster" class="slick-next"><svg aria-label="Nächster" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 35.97 51.21"><defs><style>.cls-1{fill:#28285d;}</style></defs><g id="Gruppe_31" data-name="Gruppe 31"><rect id="Rechteck_22" data-name="Rechteck 22" style="fill: #28285d" x="10.68" y="-0.15" width="14.61" height="36.26" transform="translate(-7.45 17.98) rotate(-45)"/><rect id="Rechteck_23" data-name="Rechteck 23" style="fill: #28285d" x="2.86" y="33.11" width="15.95" height="14.61" transform="translate(-25.4 19.5) rotate(-45)"/></g></svg></button>',
  });

});


document.querySelectorAll('.skip-main').forEach(function (link) {
  link.addEventListener('click', function (e) {
    e.preventDefault();
    document.getElementById('main').focus();
  });
});

document.addEventListener('DOMContentLoaded', () => {
  const images = document.querySelectorAll('.lazy');

  if (!('IntersectionObserver' in window)) {
    images.forEach(image => {
      handleImage(image);
    });
  } else {
    const config = {
      rootMargin: '100px 0px',
      threshold: 0.01,
    };
    const observer = new IntersectionObserver(observeImages, config);

    images.forEach(image => {
      observer.observe(image);
    });

    function observeImages(entries) {
      entries.forEach(entry => {
        if (entry.intersectionRatio > 0) {
          observer.unobserve(entry.target);
          handleImage(entry.target);
        }
      });
    }
  }
});


function fixHeadingOrderWithClasses() {
  // Alle <h1> bis <h6> im Dokument finden
  const headings = Array.from(document.querySelectorAll('h1, h2, h3, h4'));

  // Zähler für die SEO-Hierarchie (startet mit h1)
  let currentLevel = 1;

  // Schleife durch alle gefundenen Tags
  headings.forEach(tag => {
    // Neues Tag basierend auf der aktuellen Hierarchie erstellen
    const newTag = document.createElement(`h${currentLevel}`);

    // Die ursprüngliche Klasse, die das optische Erscheinungsbild bestimmt, hinzufügen
    newTag.className = `h${tag.tagName[1]}`; // Die ursprüngliche Nummer aus dem alten Tag extrahieren

    // Inhalt übernehmen
    newTag.innerHTML = tag.innerHTML;

    // Altes Tag ersetzen
    tag.replaceWith(newTag);

    // Zum nächsten Level hochzählen, maximal h6
    currentLevel = Math.min(currentLevel + 1, 6);
  });
}

// Funktion beim Laden der Seite ausführen
document.addEventListener('DOMContentLoaded', fixHeadingOrderWithClasses);
